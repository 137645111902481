import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyB0GrTI939HJVqXKlQ7uMk7ijWKoeEtaZk',
  authDomain: 'ohkims-5e228.firebaseapp.com',
  databaseURL: 'https://ohkims-5e228.firebaseio.com',
  projectId: 'ohkims-5e228',
  storageBucket: 'ohkims-5e228.appspot.com',
  messagingSenderId: '129429592977',
  appId: '1:129429592977:web:b986363a54f10f27d01830',
  measurementId: 'G-P04NWCNQGC',
};

function configure() {
  if (firebase.apps.length) {
    return firebase;
  }

  firebase.initializeApp(firebaseConfig);
  return firebase;
}

export default configure();
