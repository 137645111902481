import React from 'react';
import firebase from './firebase';
import { useMediaQuery } from 'beautiful-react-hooks';
import moment from 'moment';
// import _assign from 'lodash';
import './App.css';

const now = moment().format('YYYY-MM-DD');

interface ReferrerLog {
  created: Date;
  from: string;
}

function App() {
  const isMobile = useMediaQuery('(max-width: 991px)');

  function navgateToOhkimsO() {
    if (window.document) {
      const collectionRef = firebase.firestore().collection('statistics');
      const docRef = collectionRef.doc(now);

      docRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          const data = docSnapshot.data();
          if (data) {
            const referrerLogs = data.referrer as ReferrerLog[];
            if (referrerLogs) {
              docRef.update('referrer', [
                ...referrerLogs,
                {
                  created: new Date(),
                  from: window.document.referrer,
                },
              ]);
            } else {
              docRef.update('referrer', [
                {
                  created: new Date(),
                  from: window.document.referrer,
                },
              ]);
            }
          }
        } else {
          collectionRef.doc(now).set({
            referrer: [
              {
                created: new Date(),
                from: window.document.referrer,
              },
            ],
          });
        }
      });
    }
  }

  return (
    <div className='App'>
      {isMobile ? (
        <div className='wrap'>
          <img
            className='header'
            src='/images/mobile/header.png'
            srcSet='/images/mobile/header.png 991w, /images/mobile/header@2x.png 1982w, /images/mobile/header@3x.png 2973w'
            alt='오킴스 성형외과 의원'
            style={{ width: '100%' }}
          />
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            onClick={navgateToOhkimsO}
          >
            <a
              href={process.env.REACT_APP_OHKIMSO}
              className='link-wrap'
              style={{ width: '48.6%' }}
            >
              <img
                className='link-mobile'
                src='/images/mobile/ohkimso.png'
                srcSet='/images/mobile/ohkimso.png 710w, /images/mobile/ohkimso@2x.png 1420w, /images/mobile/ohkimso@3x.png 2130w'
                alt='오킴스오성형외과'
                style={{ width: '100%' }}
              />
            </a>
            <a
              href={process.env.REACT_APP_OHKIMSK}
              className='link-wrap'
              style={{ width: '48.6%' }}
            >
              <img
                className='link-mobile'
                src='/images/mobile/ohkimsk.png'
                srcSet='/images/mobile/ohkimsk.png 710w, /images/mobile/ohkimsk@2x.png 1420w, /images/mobile/ohkimsk@3x.png 2130w'
                alt='오킴스케이성형외과'
                style={{ width: '100%', marginTop: '-3px' }}
              />
            </a>
          </div>
        </div>
      ) : (
        <div className='wrap'>
          <img
            className='header'
            src='/images/desktop/header.png'
            srcSet='/images/desktop/header.png 1920w, /images/desktop/header@2x.png 3840w, /images/desktop/header@3x.png 5760w'
            alt='오킴스 성형외과 의원'
            style={{ width: '100%' }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <a
              href={process.env.REACT_APP_OHKIMSO}
              className='link-wrap'
              style={{ width: '37%' }}
            >
              <img
                className='link'
                src='/images/desktop/ohkimso.png'
                srcSet='/images/desktop/ohkimso.png 710w, /images/desktop/ohkimso@2x.png 1420w, /images/desktop/ohkimso@3x.png 2130w'
                alt='오킴스오성형외과'
                style={{ width: '100%' }}
              />
            </a>
            <img
              src='/images/desktop/desc.png'
              srcSet='/images/desktop/desc.png 401w, /images/desktop/desc@2x.png 802w, /images/desktop/desc@3x.png 1203w'
              alt='설명'
              style={{ width: '20.9%', height: 'calc(100vw * 367 / 1920)' }}
            />
            <a
              href={process.env.REACT_APP_OHKIMSK}
              className='link-wrap'
              style={{ width: '37%' }}
            >
              <img
                className='link'
                src='/images/desktop/ohkimsk.png'
                srcSet='/images/desktop/ohkimsk.png 710w, /images/desktop/ohkimsk@2x.png 1420w, /images/desktop/ohkimsk@3x.png 2130w'
                alt='오킴스케이성형외과'
                style={{ width: '100%' }}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
